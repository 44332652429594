import { Box, Grid, TextField, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Member, MemberMetaData } from '../../api/models/member';
import dayjs from 'dayjs';

const defaultMetaData = {
    "nickName": '',
    "seasonsExp": 0,
    "school": '',
    "birthplace": '',
    "birthdate": new Date(),
    "height": 0,
    "weight": 0,
    "hobby": '',
    "favoriteTeam": '',
    "parentPhone": '',
    "parentEmail": '',
    "favoritePosition": '',
    "uniqueFact": '',
    "gradeClass": ''
}

export interface MemberTabProps {
    member: Member,
    isLoading: boolean,
    onSubmit: (metaData: MemberMetaData) => void
}

export default function MemberCardDataTab(props: MemberTabProps) {

    const [metaData, setMetaData] = React.useState<MemberMetaData>(defaultMetaData);
    const [birthDateString, setBirthDateString] = React.useState<string>('');

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();


    React.useEffect(() => {
        if (props.member?.metaData) {

            props.member.metaData.seasonsExp = props.member.metaData.seasonsExp === undefined ? 0 : props.member.metaData.seasonsExp;

            setMetaData(props.member.metaData);
        }

        if (props.member?.birthDate) {
            setBirthDateString(dayjs(props.member.birthDate).format('MM/DD/YYYY'));
        }
    }, []);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        console.log(`setting ${id} to ${value}`);
        setMetaData({ ...metaData, [id]: value });
    };

    const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        console.log(`setting ${id} to ${value}`);

        let height = undefined;
        if(value.includes('-')){
            const feetAndInches = value.split('-');
            const feet = parseInt(feetAndInches[0]);
            const inches = parseInt(feetAndInches[1]);
            height = (feet * 12) + inches;
        }
        else{
            height = parseInt(value);
        }

        setMetaData({ ...metaData, height: height });
    };

    const handleBirthdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e) {
            const { value } = e.target;
            setBirthDateString(value);
            const date = dayjs(value);
            const birthdate = date.toDate();
            //setMetaData({ ...metaData, 'birthdate': birthdate });
        }

    };

    const updateMetaData = async () => {
        console.log(`updating meta data`, metaData);
        props.onSubmit(metaData);
    };

    return (
        <Grid container spacing={1}>

            <Grid item xs={4}>
                <Box>
                    <TextField id="nickName"
                        label="NickName"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.nickName}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Box>
                    <TextField id="school"
                        label="School"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.school}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>


            <Grid item xs={4}>
                <Box>
                    <TextField id="seasonsExp"
                        label="Seasons Experience"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.seasonsExp}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>


            <Grid item xs={4}>
                <Box>
                    <TextField id="favoritePosition"
                        label="Favorite Position"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.favoritePosition}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Box>
                    <TextField id="height"
                        label="Height"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.height}
                        style={{ width: 275 }}
                        onChange={handleHeightChange} />
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <TextField id="weight"
                        label="Weight"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.weight}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>

            {/* <Grid item xs={4}>
                <Box>
                    <TextField id="birthdate"
                        label="Birthdate"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={birthDateString}
                        style={{ width: 275 }}
                        onChange={handleBirthdayChange} />
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <TextField id="birthplace"
                        label="Birthplace"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.birthplace}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid> */}

            <Grid item xs={4}>
                <Box>
                    <TextField id="hobby"
                        label="Hobby"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.hobby}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <TextField id="favoriteTeam"
                        label="FavoriteTeam"
                        variant="outlined"
                        size="small"
                        helperText='20 character limit'
                        inputProps={{ maxLength: 20 }}
                        value={metaData.favoriteTeam}
                        style={{ width: 275 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>

            <Grid item xs={8}>
                <Box>
                    <TextField id="uniqueFact"
                        label="Unique Fact"
                        variant="outlined"
                        size="small"
                        helperText='40 character limit'
                        inputProps={{ maxLength: 55 }}
                        value={metaData.uniqueFact}
                        style={{ width: 500 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>

            <Grid item xs={8}>
                <Box>
                    <TextField id="gradeClass"
                        label="Class"
                        variant="outlined"
                        size="small"
                        helperText='40 character limit'
                        inputProps={{ maxLength: 55 }}
                        value={metaData.gradeClass}
                        style={{ width: 500 }}
                        onChange={handleInputChange} />
                </Box>
            </Grid>

            <Grid item xs={4}>
                {isAuthenticated && !props.isLoading && <Button variant='contained' onClick={updateMetaData}>Update MetaData</Button>}
                {props.isLoading && <CircularProgress />}
            </Grid>
        </Grid>
    )
}